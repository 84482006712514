@import '../../../../../styles/base/color';


.dashboard {
    .summary {
        h1 { 
            font-size: 1.25rem; 
            font-weight: 700;
        }
    }
}