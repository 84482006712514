@import '../../../../../../../../styles/base/color';

.table-results {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  @media screen and (max-width: 480px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
  table {
    width: 90%;
    border-radius: 10px;
    border: 1px solid #2157320b;
    
    @media screen and (max-width: 480px) {
      width: 100%;
    }

    td, th {
      padding: 10px;
    }

    thead {
      th {
        background-color:$primary; 
        color:#FFFFFF;
        font-weight: 500;
        border: 1px solid $primary;
      }
    }

    tbody {
      tr { 
        color: $fontSecondary;
        &:nth-child(even) {
          background-color: #2157320b;
        }
      }
    }
  }

  &__selected {
      background-color: $primaryLight !important;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
  }
}