@import '../../../../../../styles/main.scss';

.presupuestos-ec-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__container {
        width: 100%;
        &--row {
            width: 100%;
            display: grid;
            grid-template-columns: 0.2fr 1.5fr 1.5fr 1.5fr 2.5fr;
            padding: 5px 0;
            border-bottom: 1px solid #2157320b;

            input {
                height: 16px;
                cursor: pointer;
            }
            p,
            input {
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $fontSecondary !important;
            }

            &.header p {
                font-weight: 600 !important;
            }

            &.each-row {
                min-height: 46px;
                padding: 8px 0;
                &:nth-child(even) {
                    background-color: #2157320b;
                }
            }

            &.skeleton {
                p {
                    @include skeletonBasicsAnimation(#e0e0e0, #f4f4f4);
                    padding: 0;
                    height: 14px;
                    align-self: center;
                    justify-self: center;
                    border-radius: 4px;
                    &.p-input {
                        width: 14px;
                    }
                    &.p-presupuesto {
                        width: 55px;
                    }
                    &.p-fecha {
                        width: 60px;
                    }
                    &.p-cliente {
                        width: 90px;
                    }
                    &.p-telefono {
                        width: 65px;
                    }
                }
            }
        }

        &--no-data {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 46px;
          background-color: #2157320b;
        }
    }

    .btn.btn {
        padding: 7px !important;
        height: 42px;
        width: 160px;
    }
}
