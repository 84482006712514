@import '../../../../../../../../styles/base/color';
@import '../../../../../../../../styles/mixins';

.mySwiper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px 24px;

  @media screen and (max-width: 768px) {
    padding: 16px 0;
  }
  
  & > div {
      position: relative;
      width: 100%;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 24px 0 !important;
    .img-default {
        max-width:  230px;
      }
    }
  
    img {
      border-radius: 8px;
    }
    
    .swiper {
      height: 100%;
    }
    
    .swiper-slide {
      align-self: center;
      text-align: center;
      font-size: 18px;
      background: #fff;
      width: 100% !important;
        
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
        
      .img-default {
        margin: 24px 0 !important;
      }
    }
      
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 160px;
      max-width: 230px;
      object-fit: contain;
    }

    &__container-skeleton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6px 0;
      .skeleton-img-slider {
        border-radius: 6px;
        height: 160px;
        width: 230px;
        &__img {
          height: 100%;
          width: 100%;
        }
      }
  
      .skeleton-animation-slider {
        @include skeletonBasicsAnimation($skeletonSecondary, $skeletonPrimary)
      }
    }

}
  
