@import '../../../../../../styles/main.scss';
#steps-autocomplete {
  display: flex;
  align-items: flex-end;
  gap: 10px;

  input {
    border: none !important;
    background-color: transparent !important;
    width: 190px !important;
    padding: 13px 13px 16px !important;
  }
  label {
    font-family: $rubik;
    font-size: 14px !important;
  }
}